import marker from '../../../assets/icons/markerWhite.svg'
import s from './index.module.scss'

const Experience = ({ currentVacancy, imageName }) => {

  return (
    <>
      <div className={s['experience']}>
        <div className={s['experience__container']  + ' ' +s['experience__container--' + imageName]}>
          <p className={s['experience__text']}>
            {currentVacancy.experience.text}
          </p>
          <div className={s['experience__list']}>
            {currentVacancy.experience.list.map((p, index) => (
              <div className={s['experience__list-item']} key={index}>
                <img
                  className={s['experience__list-item__marker']}
                  src={marker}
                  alt={'✓'}
                />
                <p className={s['experience__list-item__text']}>
                  {p}
                </p>
              </div>
            ))}
          </div>

          { currentVacancy.experience.secondList ?
            (
              <>
              <p className={s['experience__text']}>
                {currentVacancy.experience.secondText}
              </p>
              <div className={s['experience__list']}>
                {currentVacancy.experience.secondList.map((p, index) => (
                  <div className={s['experience__list-item']} key={index}>
                    <img
                      className={s['experience__list-item__marker']}
                      src={marker}
                      alt={'✓'}
                    />
                    <p className={s['experience__list-item__text']}>
                      {p}
                    </p>
                  </div>
                ))}
              </div>
            </>
            ) : <></>
          }

        </div>
      </div>
    </>
  )
}

export default Experience
