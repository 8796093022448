import s from './index.module.scss'

const Abilities = ({ currentVacancy }) => {

  return (
    <>
      <div className={s['abilities']}>
        <div className={s['abilities__container']}>
          <h1 className={s['abilities__title']}>{currentVacancy.abilities.title}</h1>
          <div className={s['abilities__circles-wrapper']}>
            <div className={s['abilities__circles']}>
              <div className={s['abilities__circle'] + ' ' + s['abilities__circle--gray']}>
              {currentVacancy.abilities.circleGray}
              </div>
              <div className={s['abilities__circle'] + ' ' + s['abilities__circle--yellow']}>
              {currentVacancy.abilities.circleYellow}
              </div>
              <div className={s['abilities__circle'] + ' ' + s['abilities__circle--red']}>
              {currentVacancy.abilities.circleRed}
              </div>
              <div className={s['abilities__circle'] + ' ' + s['abilities__circle--black']}>
              {currentVacancy.abilities.circleBlack}
              </div>
              <div className={s['abilities__circle'] + ' ' + s['abilities__circle--turquoise']}>
              {currentVacancy.abilities.circleTurquoise}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Abilities
