const en_Us = {

  back: "Back",
  applyNow: 'Apply now',
  home: {
    description: {
      title: <>Join <br /> the Maib <br /> team</>,
      text: "be part of the biggest digital transformation in Moldova",
      button: "To vacancies"
    },
    abilities: {
      circleGrayTitle: <>We create <br /> products</>,
      circleGray: "that are used by hundreds of thousands of people in Moldova and influence the life of the country",
      circleRedTitle: "We are starting the transition to the Agile model",
      circleRed: "and are radically changing our internal processes and structure",
      circleTurquoiseTitle: <>We are <br /> looking for <br /> strong people</>,
      circleTurquoise: "in order to create the backbone of a future strong product company",
    },
    vacancies: {
      title: "We are looking for",
      engineering: {
        title: "Engineering",
        list: {
          netDeveloper: ".NET developer",
          javaDeveloper: "Java developer",
          creatioLowCoder: "Creatio low coder",
          devOps: "DevOps",
          webDeveloper: "Web developer (JS)",
          iosDeveloper: "iOS developer",
          engineerWindows: "Systems Administrator/Engineer (OS Windows)",
          engineerLinux: "Systems Administrator/Engineer (Linux OS)",
          programator: "Programator, Departamentul Tehnologii Informationale",
        }
      },
      qualityAssurance: {
        title: "Quality assurance",
        list: {
          qaAutomation: "QA Automation",
        }
      },
      design: {
        title: "Design",
        list: {
          designer: <>UI\UX Designer</>,
        }
      }
    },
    theWayWeWork: {
      title: "The way we work",
      list: [
        "Small autonomous, cross-functional teams",
        "Empowered to make decisions",
        "Focused only on a few priorities",
        "Flexible seating arrangements and work-from-home",
      ]
    },
  },

  vacancies: {
    netDeveloper: {
      description: {
        title: ".NET developer",
        text: [
          "We are looking for .NET developers (from Junior to Senior/Expert) for developing bank products and services for legal entities and individuals. If you love innovating and building intelligent solutions, join us.",
          "You’ll definitely enjoy working  at maib!"
        ]
      },
      firstThings: {
        title: "The first things you will do",
        list: [
          "Participate in the elaboration, improvement and maintenance of bank applications",
          "Build and use microservices or ensure other ways of integration among different systems",
          "Active involvement and high responsibility in bug fixing",
          "Ensure a high level of performance and quality of developed applications",
          "Identify and correct performance issues and bottlenecks",
          "Implement and deploy the tests, integration tests and removal of detected bugs",
          "Maintain code quality, participate in the development of automated tests",
          "Include the developments in the DevOps flow (CI/CD) applied in the bank",
          "Collaborate proactively with colleagues and bank partners, to achieve the team’s objectives"
        ]
      },
      experience: {
        text: "Proven knowledge and experience in middle-end or back-end application development:",
        list: [
          ".NET (C# preferred)",
          "Objective-C",
          "Object-oriented programming",
          "Design Patterns",
          "IOC",
        ]
      },
      qualifications: {
        title: "Qualifications you will need",
        list: [
          "Good knowledge of Web services design, RESTful APIs, RPC, SOAP;",
          "Good knowledge and experience designing and querying SQL databases (MSSQL, or Postgresql, or MySql);",
          "Strong knowledge of applications & APIs design principles, patterns, and best practices, including SOLID;",
          "Experience working with JSON, XML, binary formats;",
          "Asynchronous and multithreading programming experience;",
          "Code design/writing principles/practices from an information security perspective, compliance with generally applicable standards;",
          "Ability of writing clear, able to be checked code (collect metrics and warnings) and easy to test.",
        ],
        subtitle: "Will be an advantage",
        advantageList: [
          "Git, ASP.NET MVC, ORM like Entity Framework, NHibernate, Dapper (Entity Framework preferred);",
          "Knowledge in technologies and tools: Docker, Kubernetes, Jenkins, TeamCity, Apache Kafka/Rabbit MQ;",
          "Inversion of control knowledge, WCF framework knowledge;",
          "Log Frameworks (Serilog, NLog, Log4net);",
          "Web development (ASP.NET Web Forms, ASP.NET MVC, Blazor).",
        ]

      },
      abilities: {
        title: "Abilities",
        circleGray: "Open minded and self-driver",
        circleYellow: "Oriented to the results and strong organized",
        circleRed: "Effective communication skills, self-confidence and good team player",
        circleBlack: "Understanding of business requirements and turning them into technical",
        circleTurquoise: "Attentive to the details, flexible and collaborative",
      },

      offer: {
        title: "What we offer:",
        list: [
          "Flexibility: Hybrid, remote or in the office work;",
          "Competitive salary and benefits as we are the first bank in Agile Transformation;",
          "Lunch tickets, preferred loans and corporate number;",
          "A huge range of additional discounts for different brands;",
          "Great opportunities to have autonomy in your position, to enjoy professional life together with our IT team and to be THE key contributor in building an agile bank",
        ]
      },
    },

    seniorDesigner: {
      description: {
        title: "Senior UI/UX designer",
        text: [
          "Now we are looking for UI/UX designer to develop and bring our FinTech products, banking services to the next level.",
          "You’ll definitely enjoy working at maib!"
        ]
      },
      firstThings: {
        title: "The first things you will do",
        list: [
          "Create of digital advertising – web banners, site layouts and social networks",
          "Create landings pages and writing the existing site",
          "Participate and involvement in the development of internals projects",
          "Develop user interfaces",
          "Study product metrics to identify service break points",
          "Generate ideas for development projects",
          "Collaborate with the developers and the project creation team",
        ]
      },
      experience: {
        text: "Proven knowledge and experience in UI/UX using:",
        list: [
          "Sketch", "Figma", "Zeplin"
        ],
        secondText: "Proven knowledge of:",
        secondList: [
          "iOS guidelines", "Android guidelines",
        ]
      },
      qualifications: {
        title: "We expect you to have",
        list: [
          "Portfolio",
          "At least 3 years of experience as UI/UX designer;",
          "Higher education, preferable in design and art;",
          "Experience in building CJM;",
          "Experience in designing B2B tools and understanding methods of Agile Scrum",
        ],
      },
      abilities: {
        title: "Abilities",
        circleGray: "Open minded and self-driver",
        circleYellow: "Oriented to the results and strong organized",
        circleRed: "Effective communication skills, self-confidence",
        circleBlack: "Attentive to the details, flexible and collaborative",
        circleTurquoise: <>Good <br />team <br /> player</>,
      },

      offer: {
        title: "What we offer:",
        list: [
          "Flexibility: Hybrid, remote or in the office work;",
          "Competitive salary and benefits as we are the first bank in Agile Transformation;",
          "Lunch tickets, preferred loans and corporate number;",
          "A huge range of additional discounts for different brands;",
          "Great opportunities to have autonomy in your position, to enjoy professional life together with our IT team and to be THE key contributor in building an agile bank",
        ]
      },

    },

    webDeveloper: {
      description: {
        title: "Web developer",
        text: [
          "Now we are looking for Web developers (from Junior to Senior/Expert) for the projects developing bank products and services for legal entities and individuals. If you love innovating and building intelligent solutions, join us.",
          "You’ll definitely enjoy working at maib!"
        ]
      },
      firstThings: {
        title: "The first things you will do",
        list: [
          "Participate in the elaboration, improvement and maintenance of bank applications",
          "Build and use microservices or ensure other ways of integration among different systems",
          "Ensure a high level of performance, quality and user experience (UX) of developed applications",
          "Active involvement and high responsibility in bug fixing",
          "Identify and correct performance issues and bottlenecks",
          "Implement and deploy the tests, integration tests and removal of detected bugs",
          "Maintain code quality, participate in the development of automated tests",
          "Include the developments in the DevOps flow (CI/CD) applied in the bank/outsourced company",
          "Collaborate proactively with colleagues and bank partners, to achieve the team’s objectives"
        ]
      },
      experience: {
        text: "Proven knowledge and experience in font end WEB application development:",
        list: [
          "HTML5",
          "JavaScript",
          "NodeJS",
          "CSS3",
          "Design Patterns",
        ]
      },
      qualifications: {
        title: "Qualifications you will need",
        list: [
          "In-depth knowledge of popular WEB development frameworks like Angular/React and JavaScript libraries (jQuery, Knockout, requireJS);",
          "Good knowledge of Web services design, RESTful APIs, RPC, SOAP;",
          "Strong knowledge of applications & APIs design principles, patterns, and best practices, including SOLID, DRY;",
          "Good understanding of code versioning tools such as Git, Bitbucket and CI/CD pipeline;",
          "Understanding of development patterns, algorithms and data structures;",
          "Good knowledge and experience designing and querying SQL databases (MSSQL, or Postgresql, or MySql);",
          "Experience in building tools (webpack, npm, yarn, etc.);",
          "Experience in testing and mocking frameworks (Qunit, MochaJS, Jasmine);",
          "Experience to work with xml, JSON, Linux operating system (including the use of CLI);",
          "Code design / writing principles / practices from an information security perspective, compliance with generally applicable standards in this field.",
        ],
      },
      abilities: {
        title: "Abilities",
        circleGray: "Open minded and self-driver",
        circleYellow: "Oriented to the results and strong organized",
        circleRed: "Effective communication skills, self-confidence and good team player",
        circleBlack: "Understanding of business requirements and turning them into technical",
        circleTurquoise: "Attentive to the details, flexible and collaborative",
      },

      offer: {
        title: "What we offer:",
        list: [
          "Flexibility: Hybrid, remote or in the office work;",
          "Competitive salary and benefits as we are the first bank in Agile Transformation;",
          "Lunch tickets, preferred loans and corporate number;",
          "A huge range of additional discounts for different brands;",
          "Great opportunities to have autonomy in your position, to enjoy professional life together with our IT team and to be THE key contributor in building an agile bank",
        ]
      },
    },

    iosDeveloper: {
      description: {
        title: "iOS developer",
        text: [
          "Now we are looking for iOS Developers (from Junior to Senior/Expert – as leader for iOS developer team) for the project of developing mobile bank applications for legal entities and individuals. If you love innovating and building digital solutions, join us.",
          "You’ll definitely enjoy working at maib!"
        ]
      },
      firstThings: {
        title: "The first things you will do",
        list: [
          "Participate according to the competences in the elaboration, improvement and maintenance of mobile applications for B2C and B2B customers",
          "Responsible for the refactoring in collaboration with the outsourced software developing company",
          "Ensure a high level of performance, quality and user experience (UX) of developed applications",
          "Active involvement and high responsibility in bug fixing",
          "Identify and correct performance issues and bottlenecks",
          "Implement and deploy the tests, integration tests and removal of detected bugs",
          "Maintain code quality, participate in the development of automated tests",
          "Include the developments in the DevOps flow (CI/CD) applied in the bank/outsourced company",
          "Collaborate proactively with colleagues and bank partners, to achieve the team’s objectives"
        ]
      },
      experience: {
        text: "Proven knowledge and experience in middle-end or back-end application development:",
        list: [
          "SWIFT stack",
          "Object-oriented programming",
          "Design Patterns",
          "IOC",
        ]
      },
      qualifications: {
        title: "Qualifications you will need",
        list: [
          "Familiarity with RESTful APIs to connect iOS applications to back-end services;",
          "Strong knowledge of iOS UI design principles, patterns, and best practices, including SOLID;",
          "Knowledge in technologies and tools: Docker, Kubernetes, Jenkins, TeamCity, iOS testing frameworks;",
          "Experience in Git, Templates for building UI - MVP/VIPER, MVC;",
          "Basic knowledge of SQL databases (or SQLite), Linux operating system (including the use of CLI);",
          "Code design / writing principles / practices from an information security perspective, compliance with generally applicable standards in this field.",
          "Ability of writing clear, able to be checked code (collect metrics and warnings) and easy to test.",
        ],
      },
      abilities: {
        title: "Abilities",
        circleGray: "Open minded and self-driver",
        circleYellow: "Oriented to the results and strong organized",
        circleRed: "Effective communication skills, self-confidence and good team player",
        circleBlack: "Understanding of business requirements and turning them into technical",
        circleTurquoise: "Attentive to the details, flexible and collaborative",
      },

      offer: {
        title: "What we offer:",
        list: [
          "Flexibility: Hybrid, remote or in the office work;",
          "Competitive salary and benefits as we are the first bank in Agile Transformation;",
          "Lunch tickets, preferred loans and corporate number;",
          "A huge range of additional discounts for different brands;",
          "Great opportunities to have autonomy in your position, to enjoy professional life together with our IT team and to be THE key contributor in building an agile bank",
        ]
      },
    },

    engineerWindows: {
      description: {
        title: "Systems Administrator / Engineer (OS Windows)",
        text: [
          "Now we are looking for Systems Administrator/Engineer (OS Windows) for the projects developing bank products and services for legal entities and individuals. If you love innovating and building intelligent solutions, join us.",
          "You’ll definitely enjoy working at maib!"
        ]
      },
      firstThings: {
        title: "The first things you will do",
        list: [
          "Installation, configuration, administration of Windows Server operating systems",
          "Manage and monitor server infrastructure (physical/virtual), as well as virtualization platforms (Hyper-V, VMWare)",
          "Managing Active Directory, Windows DNS/FileServer/DHCP/Group Policy/Certification Authority/IIS/WSUS, System Center",
          "Installation and configuration of software components, as well as ensuring the continuous operation of application components and servers under management",
          "Administration of backup management systems (server components, applications, databases: VEEAM, DPM)",
          "Participation in development projects of server infrastructure and software product, as well as implementation/renovation projects"
        ]
      },
      experience: {
        text: "Proven knowledge and experience in System Engineering:",
        list: [
          "Windows Server 2012R2, 2019 (Enterprise Level Roles and Features)",
          "Hyper-V, VMWare",
          "SCOM, SCCM",
          "Data Protection Manager, Veeam Backup and Replication",
          "Microsoft Exchange, Microsoft Sharepoint",
          "Citrix ADC",
        ]
      },
      qualifications: {
        title: "Qualifications you will need",
        list: [
          "Higher education in Information Technology / Computer Science;",
          "Minimum 2 years of experience in a similar position;",
          "Advanced knowledge of Windows Server and Windows Server roles in Enterprise infrastructure;",
          "Basic knowledge in the use of PowerShell, CLI, High-Availability hardware/software and Disaster Recovery, as well as Networking;",
          "Knowledge of English specific to technical areas."
        ],
      },
      abilities: {
        title: "Abilities",
        circleGray: "Open minded and self-driver",
        circleYellow: "Oriented to the results and strong organized",
        circleRed: "Effective communication skills, self-confidence and good team player",
        circleBlack: "Understanding of business requirements and turning them into technical",
        circleTurquoise: "Attentive to the details, flexible and collaborative",
      },

      offer: {
        title: "What we offer:",
        list: [
          "Competitive salary and benefits as we are the first bank in Agile Transformation;",
          "Lunch tickets, preferred loans and corporate number;",
          "A huge range of additional discounts for different brands;",
          "Great opportunities to have autonomy in your position, to enjoy professional life together with our IT team and to be THE key contributor in building an agile bank. You are more than welcome to send the application or CV to resurseumane@maib.md and one of our recruiter will contact you in the nearest time.",
        ]
      },
    },

    programator: {
      description: {
        title: "Programator, Departamentul Tehnologii Informaționale",
        text: [
          "Now we are looking for Developer for the projects developing bank products and services for legal entities and individuals. If you love innovating and building intelligent solutions, join us.",
          "You’ll definitely enjoy working at maib!"
        ]
      },
      firstThings: {
        title: "The first things you will do",
        list: [
          "Crearea si dezvoltarea de noi interfețe pentru platforma T24"
        ]
      },
      experience: {
        text: "Proven knowledge and experience in development:",
        list: [
          "INFO BASIC - XML",
          "JavaScript",
          "Oracle",
          "Jbase",
          "MS SQL Server",
          "Eclipse",
          "Jboss",
          "Websphere",
          "TC Server",
        ]
      },
      qualifications: {
        title: "Qualifications you will need",
        list: [
          "Studii superioare în domeniul tehnologiilor informaționale;",
          "Cunoștinte de bază în limbajele de programare - INFO BASIC - XML, Java Script; Database - Oracle, Jbase, MS SQL Server, framework - Eclipse; application server - Jboss, Websphere, TC Server;",
          "Experiență de lucru pe TAFJ, constituie avantaj",
          "Experiență în dezvoltarea aplicațiilor bancare, constituie avantaj;",
          "Cunoașterea limbii engleze specific domeniilor tehnice."
        ],
      },
      abilities: {
        title: "Abilities",
        circleGray: "Open minded and self-driver",
        circleYellow: "Oriented to the results and strong organized",
        circleRed: "Effective communication skills, self-confidence and good team player",
        circleBlack: "Understanding of business requirements and turning them into technical",
        circleTurquoise: "Attentive to the details, flexible and collaborative",
      },

      offer: {
        title: "What we offer:",
        list: [
          "Flexibility: Hybrid, remote or in the office work;",
          "Competitive salary and benefits as we are the first bank in Agile Transformation;",
          "Lunch tickets, preferred loans and corporate number;",
          "A huge range of additional discounts for different brands;",
          "Great opportunities to have autonomy in your position, to enjoy professional life together with our IT team and to be THE key contributor in building an agile bank",
        ]
      },
    },

    engineerLinux: {
      description: {
        title: "Systems Administrator / Engineer (Linux OS)",
        text: [
          "Now we are looking for Systems Administrator/Engineer (Linux OS) for the projects developing bank products and services for legal entities and individuals. If you love innovating and building intelligent solutions, join us.",
          "You’ll definitely enjoy working at maib!"
        ]
      },
      firstThings: {
        title: "The first things you will do",
        list: [
          "Installation, configuration, administration of LINUX/UNIX operating systems",
          "Management and monitoring of server infrastructure",
          "Installation and configuration of software components hosted by the servers under management, as well as, ensuring the availability and continuity of operation of the application components and servers under management",
          "Participation in the investigation of accidental situations related to the systems under management, removing vulnerabilities and security breaches identified",
          "Participation in the projects related to server infrastructure development, as well as, implementation/renewal of software products"
        ]
      },
      experience: {
        text: "Proven knowledge and experience in System Engineering:",
        list: [
          "LINUX, AIX, CentOS",
          "Hyper-V, VMWare",
          "SCOM",
          "Veeam Backup and Replication",
          "Docker, Kubernetes",
          "Grafana, Prometheus, Graphite, GrayLog",
          "GitLab, Jenkins, Ansible",
          "Citrix ADC"
        ]
      },
      qualifications: {
        title: "Qualifications you will need",
        list: [
          "Higher education in Information Technology / Computer Science;",
          "Minimum 2 years of experience in a similar position;",
          "Advanced knowledge in centralized administration of WEB servers (Apache, nginx), DB (MySQL, PostgreSQL, MSSQL);",
          "Advanced knowledge of DNS, HTTP, LDAP, SMTP, SNMP protocols, as well as shell scripting;",
          "Basic knowledge of High-Availability and Disaster Recovery hardware/software;",
          "Knowledge of English specific to technical fields;"
        ],
      },
      abilities: {
        title: "Abilities",
        circleGray: "Open minded and self-driver",
        circleYellow: "Oriented to the results and strong organized",
        circleRed: "Effective communication skills, self-confidence and good team player",
        circleBlack: "Understanding of business requirements and turning them into technical",
        circleTurquoise: "Attentive to the details, flexible and collaborative",
      },

      offer: {
        title: "What we offer:",
        list: [
          "Competitive salary and benefits as we are the first bank in Agile Transformation;",
          "Lunch tickets, preferred loans and corporate number;",
          "A huge range of additional discounts for different brands;",
          "Great opportunities to have autonomy in your position, to enjoy professional life together with our IT team and to be THE key contributor in building an agile bank. You are more than welcome to send the application or CV to resurseumane@maib.md and one of our recruiter will contact you in the nearest time.",
        ]
      },
    },

    devOpsEngineer: {
      description: {
        title: "DevOps engineer",
        text: [
          "Now we are looking for DevOps Engineers (from Junior to Senior/Expert) for the projects of developing mobile bank applications for legal entities and individuals, digital and agile bank’s initiatives. If you love innovating and building digital solutions, join us.",
          "You’ll definitely enjoy working at maib!"
        ]
      },
      firstThings: {
        title: "The first things you will do",
        list: [
          "Design and Implementation of cloud Infrastructure on premise and on a variety of Cloud Providers (Azure a preference)",
          "Perform root cause analysis for production issues using Open Source technology",
          "Build tooling and software to mitigate common problems",
          "Work with the Development Teams to manage releases of software using CI/CD",
          "Keep up to date with recent news and developments in the Open Source community",
          "Ensure all software is configured against modern industry recommendations",
          "Monitor systems, applications and vulnerabilities to ensure they are healthy and secure",
          "Responsible for maintaining SLO’s / SLA’s for uptime and performance for the platform",
          "Ensure security and stability of the platform",
          "Supporting other teams with DevOps specific skillsets",
        ]
      },
      experience: {
        text: <>Proven knowledge and experience<br /> in DevOps/SRE:</>,
        list: [
          "Container Orchestration via Kubernetes;",
          "Database Technologies, Preferably MS SQL;",
          <>Continuous Integration tooling, e.g. GitLab, <br />Jenkins, Team City;</>,
          "Continuous Deployment tooling e.g. Argo CD;",
          "Cloud Providers, e.g. Azure/GCP/AWS;",
          "Linux System Administration, e.g. RHEL/CentOS;",
          "Scripting, e.g. Bash/Python/Go;",
          "Configuration Management, e.g. Ansible;",
          "Infrastructure Management, e.g. Terraform;",
          "Monitoring, e.g. Prometheus, Grafana, Graphite;",
          "Log aggregation, e.g. ELK, Splunk, Greylog.",
        ]
      },

      abilities: {
        title: "Abilities",
        circleGray: "Open minded and self-driver",
        circleYellow: "Oriented to the results and strong organized",
        circleRed: "Effective communication skills, self-confidence and good team player",
        circleBlack: "Understanding of business requirements and turning them into technical",
        circleTurquoise: "Attentive to the details, flexible and collaborative",
      },

      offer: {
        title: "What we offer:",
        list: [
          "Flexibility: Hybrid, remote or in the office work;",
          "Competitive salary and benefits as we are the first bank in Agile Transformation;",
          "Lunch tickets, preferred loans and corporate number;",
          "A huge range of additional discounts for different brands;",
          "Great opportunities to have autonomy in your position, to enjoy professional life together with our IT team and to be THE key contributor in building an agile bank",
        ]
      },
    },

    qaAutomationEngineer: {
      description: {
        title: "QA automation engineer",
        text: [
          "Now we are looking for QA automation Engineers (from Junior to Senior/Expert) for the project of developing mobile bank applications for legal entities and individuals, digital and agile bank’s initiatives. If you love innovating and building digital solutions, join us.",
          "You’ll definitely enjoy working at maib!"
        ]
      },
      firstThings: {
        title: "The first things you will do",
        list: [
          "Responsible for ensuring our testing stack is well scoped, monitored and documented",
          "Writing automated tests to support our platforming efforts, driving confidence in our software releases",
          "Mentoring to Development Engineers in QA practices with a view of improving quality and efficiency",
          "Testing a combination of UI, API, Database, configuration and units of code",
          "Keeping our QA best practice standards up to date, supporting documentation, onboarding and maintenance",
          "Analyzing bugs across the stack and ensuring that suitable tests are set up to avoid regression",
          "Working with the rest of the team to review both your code, QA engineering and software engineers’ code before it is committed into our repositories",
          "Alignment of best practices & QA automation practices in collaboration with Team Leads",
        ]
      },
      experience: {
        text: "Strong experience in:",
        list: [
          "Selenium testing frameworks and able to build and structure automated test;",
          "JavaScript & an understanding of object orientated programming;",
          "BDD practices",
          "Basic development tools like Git and ADO",
          "Continuous delivery and DevOps best practices and how QA plays its part",
          "Continuous integration and DevOps tools and practices on Continuous Deployment",
          "Continuous integration practices with a view on how QA can assist with Continuous Deployment strategies",
        ]
      },
      qualifications: {
        title: "Qualifications you will need",
        list: [
          "Understanding and identifying Acceptance Criteria;",
          "Strong understanding of unit, integration and cross browser/mobile testing approaches and services (like Browserstack Automate or SourceLabs).",
          "Confidence in implementing and testing APIs with Postman, Jmeter, k6 or similar"
        ],
      },
      abilities: {
        title: "Abilities",
        circleGray: "Open minded and self-driver",
        circleYellow: "Oriented to the results and strong organized",
        circleRed: "Effective communication skills, self-confidence and good team player",
        circleBlack: "Understanding of business requirements and turning them into technical",
        circleTurquoise: "Attentive to the details, flexible and collaborative",
      },

      offer: {
        title: "What we offer:",
        list: [
          "Flexibility: Hybrid, remote or in the office work;",
          "Competitive salary and benefits as we are the first bank in Agile Transformation;",
          "Lunch tickets, preferred loans and corporate number;",
          "A huge range of additional discounts for different brands;",
          "Great opportunities to have autonomy in your position, to enjoy professional life together with our IT team and to be THE key contributor in building an agile bank",
        ]
      },
    },

    javaDeveloper: {
      description: {
        title: <>Java <br /> developer</>,
        text: [
          "Now we are looking for Java Developers (from Junior to Senior/Expert) for developing bank products and services for legal entities and individuals. If you love innovating and building intelligent solutions, join us.",
          "You’ll definitely enjoy working at maib!"
        ]
      },
      firstThings: {
        title: "The first things you will do",
        list: [
          "Participate according to the competences in the elaboration, improvement and maintenance of bank applications",
          "Build and use microservices or ensure other ways of integration among different systems",
          "Ensure a high level of performance, quality and user experience (UX) of developed applications",
          "Active involvement and high responsibility in bug fixing",
          "Identify and correct performance issues and bottlenecks",
          "Implement and deploy the tests, integration tests and removal of detected bugs",
          "Maintain code quality, participate in the development of automated tests",
          "Include the developments in the DevOps flow (CI/CD) applied in the bank/outsourced company",
          "Collaborate proactively with colleagues and bank partners, to achieve the team’s objectives"
        ]
      },
      experience: {
        text: "Proven knowledge and experience in:",
        list: [
          "Java",
          "Java frameworks like Spring (Boot, Core, MVC) and JPA (Hibernate)",
          "OOP",
          "Design Patterns",
          "IOC",
        ]
      },
      qualifications: {
        title: "Qualifications you will need",
        list: [
          "Good knowledge of Web services design (including SOAP), Web API, RESTful APIs;",
          "Strong knowledge of applications & APIs design principles, patterns, and best practices, including SOLID;",
          "Understanding of development patterns, algorithms and data structures;",
          "Good knowledge and experience designing and querying SQL databases;",
          "Experience in building tools (any IDE, maven and/or gradle, Git);",
          "Experience in testing and mocking frameworks (jUnit, Mockito);",
          "Experience to work with xml, JSON, Linux operating system (including the use of CLI);",
          "Code design / writing principles / practices from an information security perspective, compliance with generally applicable standards in this field",
        ],
      },
      abilities: {
        title: "Abilities",
        circleGray: "Open minded and self-driver",
        circleYellow: "Oriented to the results and strong organized",
        circleRed: "Effective communication skills, self-confidence and good team player",
        circleBlack: "Understanding of business requirements and turning them into technical",
        circleTurquoise: "Attentive to the details, flexible and collaborative",
      },

      offer: {
        title: "What we offer:",
        list: [
          "Flexibility: Hybrid, remote or in the office work;",
          "Competitive salary and benefits as we are the first bank in Agile Transformation;",
          "Lunch tickets, preferred loans and corporate number;",
          "A huge range of additional discounts for different brands;",
          "Great opportunities to have autonomy in your position, to enjoy professional life together with our IT team and to be THE key contributor in building an agile bank",
        ]
      },
    },

    creatioLowCoder: {
      description: {
        title: <>Creatio <br /> low coder</>,
        text: [
          "Now we are looking for Seniors Creatio Low Coder for developing bank products and services for legal entities and individuals. If you love innovating and building intelligent solutions, join us.",
          "You’ll definitely enjoy working at maib!"
        ]
      },
      firstThings: {
        title: "The first things you will do",
        list: [
          "Participate according to the competences in the elaboration, improvement and maintenance of bank applications",
          "Working with Creatio configurations and the customization process",
          "Creatio Package and Schema Management",
          "Setting up interfaces, cases, processes according to the requirements",
          "Setting task for integration developers",
          "Work with SVN, compliance with established norms and regulations",
          "Setting up registries, details, dashboards, dynamic groups, static groups",
          "Ensure a high level of performance, quality and user experience (UX) of developed applications",
          "Active involvement and high responsibility in bug fixing",
          "Identify and correct performance issues and bottlenecks",
          "Implement and deploy the tests, integration tests and removal of detected bugs",
          "Maintain code quality, participate in the development of automated tests of the developed products",
          "Include the developments in the DevOps flow (CI/CD) applied in the bank",
          "Collaborate proactively with colleagues and bank partners, to achieve the team’s objectives",
          "Knowledge transfer and training of Low Code Creatio specialist teams"
        ]
      },
      experience: {
        text: "Proven knowledge and experience in:",
        list: [
          "Implementation of IT projects (experience from 3 years), so you know how to set technical specifications and form the architecture of an information system;",
          "Creatio configurations and the customization process;",
          "Creating from scratch the concept of implementing an information system and automating a new business process;",
          "Relational DBMS;",
          "Algorithms",
        ]
      },
      qualifications: {
        title: "Qualifications you will need",
        list: [
          "Basic knowledge of information systems administration;",
          "Ability to write simple programs;",
          "Install Creatio locally on your computer;",
          "Write SQL queries",
        ],
      },
      abilities: {
        title: "Abilities",
        circleGray: "Open minded and self-driver",
        circleYellow: "Oriented to the results and strong organized",
        circleRed: "Effective communication skills, self-confidence and good team player",
        circleBlack: "Understanding of business requirements and turning them into technical",
        circleTurquoise: "Attentive to the details, flexible and collaborative",
      },

      offer: {
        title: "What we offer:",
        list: [
          "Flexibility: Hybrid, remote or in the office work;",
          "Competitive salary and benefits as we are the first bank in Agile Transformation;",
          "Lunch tickets, preferred loans and corporate number;",
          "A huge range of additional discounts for different brands;",
          "Great opportunities to have autonomy in your position, to enjoy professional life together with our IT team and to be THE key contributor in building an agile bank",
        ]
      },
    },
  }
}


export default en_Us
