import Header from '../components/Header'
import Footer from '../components/Footer'
import DescriptionHome from '../components/Home/DescriptionHome'
import AbilitiesHome from '../components/Home/AbilitiesHome'
import VacanciesHome from '../components/Home/VacanciesHome'
import TheWayWeWork from '../components/Home/TheWayWeWork'
import en_Us from '../Language/text'
import s from './index.module.scss'
import Slider from '../components/Home/Slider'
import Video from '../components/Video'
import ScrollToVacancies from '../components/ScrollToVacancies'

const Home = () => {
  return (
    <>
      <Header />
      <div className={s['home']}>

        <DescriptionHome />
        <AbilitiesHome />
        <VacanciesHome />
        <TheWayWeWork />
        <Slider />
        <Video />


        <div
          className={s['home__button']}
          onClick={ScrollToVacancies}
        >
          <p>{en_Us.home.description.button}</p>
        </div>



      </div>
      <Footer />
    </>


  )
}

export default Home
