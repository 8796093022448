import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import s from './index.module.scss'

const Video = () => (
  <div className={s['video']}>
    <LiteYouTubeEmbed
      id='EVkzyX7RABI'
      title='Valorile maib: Suntem profesioniști'
      poster='maxresdefault'
    />
    {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/EVkzyX7RABI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
  </div>
)

export default Video
