import { Link, useLocation } from 'react-router-dom'
import iconLogo from '../../assets/icons/maib-logo.svg'
import s from './index.module.scss'

const Header = () => {
  const location = useLocation()

   return (
    <>
      <header className={s['header']}>
        {
          location.pathname === '/'
            ? (
              <a href="https://www.maib.md/" target="_blank" rel="noreferrer">
                <img
                  className={s['header__logo']}
                  src={iconLogo}
                  alt="logo"
                />
              </a>
            )
            : (
              <Link to="/">
                <img
                  className={s['header__logo']}
                  src={iconLogo}
                  alt="logo"
                />
              </Link>
            )
        }
      </header>
    </>
  )
}

export default Header
