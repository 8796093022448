import en_Us from '../../../Language/text'
import s from './index.module.scss'

const Abilities = () => {

  return (
    <>
      <div className={s['abilities-home']}>
        <div className={s['abilities-home__container']}>
          <div className={s['abilities-home__circles']}>
            <div className={s['abilities-home__circle'] + ' ' + s['abilities-home__circle--gray']}>
              <div className={s['abilities-home__circle--content']}>
                <p className={s['abilities-home__circle--title']}>{en_Us.home.abilities.circleGrayTitle}</p>
                <p className={s['abilities-home__circle--text']}>{en_Us.home.abilities.circleGray}</p>
              </div>
            </div>
            <div className={s['abilities-home__circle'] + ' ' + s['abilities-home__circle--red']}>
              <div className={s['abilities-home__circle--content']}>
                <p className={s['abilities-home__circle--title']}>{en_Us.home.abilities.circleRedTitle}</p>
                <p className={s['abilities-home__circle--text']}>{en_Us.home.abilities.circleRed}</p>
              </div>
            </div>
            <div className={s['abilities-home__circle'] + ' ' + s['abilities-home__circle--turquoise']}>
              <div className={s['abilities-home__circle--content'] + ' ' + s['abilities-home__circle--content--turquoise']}>
                <p className={s['abilities-home__circle--title']}>{en_Us.home.abilities.circleTurquoiseTitle}</p>
                <p className={s['abilities-home__circle--text']}>{en_Us.home.abilities.circleTurquoise}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Abilities
