import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import en_Us from '../../../Language/text'
import Description from '../../../components/Vacancies/Description'
import FirstThings from '../../../components/Vacancies/FirstThings'
import Experience from '../../../components/Vacancies/Experience'
import Qualifications from '../../../components/Vacancies/Qualifications'
import Abilities from '../../../components/Vacancies/Abilities'
import Offer from '../../../components/Vacancies/Offer'
import LoadJotform from '../../../components/LoadJotform'
import s from './index.module.scss'

const NetDeveloper = () => {
  const currentVacancy = en_Us.vacancies.netDeveloper

  LoadJotform()
  
  return (
    <>
      <Header />
      <div className={s['net-developer']}>
        <Description currentVacancy={currentVacancy} imageName={'net-developer'}/>
        <FirstThings currentVacancy={currentVacancy}/>
        <Experience currentVacancy={currentVacancy} imageName={'net-developer'}/>
        <Qualifications currentVacancy={currentVacancy}/>
        <Abilities currentVacancy={currentVacancy}/>
        <Offer currentVacancy={currentVacancy}/>
      </div>
      <Footer />
    </>
  )
}

export default NetDeveloper
