import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import logoDocker from '../../../assets/images/Slider/docker/docker.webp'
import logoDocker2x from '../../../assets/images/Slider/docker/docker@2x.webp'
import logoDocker3x from '../../../assets/images/Slider/docker/docker@3x.webp'
import logoKubernetes from '../../../assets/images/Slider/kubernetes/kubernetes.webp'
import logoKubernetes2x from '../../../assets/images/Slider/kubernetes/kubernetes@2x.webp'
import logoKubernetes3x from '../../../assets/images/Slider/kubernetes/kubernetes@3x.webp'
import logoJenkins from '../../../assets/images/Slider/jenkins/jenkins.webp'
import logoJenkins2x from '../../../assets/images/Slider/jenkins/jenkins@2x.webp'
import logoJenkins3x from '../../../assets/images/Slider/jenkins/jenkins@3x.webp'
import logoSonaeqube from '../../../assets/images/Slider/sonaeqube/sonaeqube.webp'
import logoSonaeqube2x from '../../../assets/images/Slider/sonaeqube/sonaeqube@2x.webp'
import logoSonaeqube3x from '../../../assets/images/Slider/sonaeqube/sonaeqube@3x.webp'
import logoSwift from '../../../assets/images/Slider/swift/swift.webp'
import logoSwift2x from '../../../assets/images/Slider/swift/swift@2x.webp'
import logoSwift3x from '../../../assets/images/Slider/swift/swift@3x.webp'
import logoKotlin from '../../../assets/images/Slider/kotlin/kotlin.webp'
import logoKotlin2x from '../../../assets/images/Slider/kotlin/kotlin@2x.webp'
import logoKotlin3x from '../../../assets/images/Slider/kotlin/kotlin@3x.webp'
import logoJava from '../../../assets/images/Slider/java/java.webp'
import logoJava2x from '../../../assets/images/Slider/java/java@2x.webp'
import logoJava3x from '../../../assets/images/Slider/java/java@3x.webp'
import logoGrafana from '../../../assets/images/Slider/grafana/grafana.webp'
import logoGrafana2x from '../../../assets/images/Slider/grafana/grafana@2x.webp'
import logoGrafana3x from '../../../assets/images/Slider/grafana/grafana@3x.webp'
import logoSql from '../../../assets/images/Slider/sql/sql.webp'
import logoSql2x from '../../../assets/images/Slider/sql/sql@2x.webp'
import logoSql3x from '../../../assets/images/Slider/sql/sql@3x.webp'
import logoC from '../../../assets/images/Slider/c/c.webp'
import logoC2x from '../../../assets/images/Slider/c/c@2x.webp'
import logoC3x from '../../../assets/images/Slider/c/c@3x.webp'
import logoArgo from '../../../assets/images/Slider/argo/argo.webp'
import logoArgo2x from '../../../assets/images/Slider/argo/argo@2x.webp'
import logoArgo3x from '../../../assets/images/Slider/argo/argo@3x.webp'
import logoNode from '../../../assets/images/Slider/node/node.webp'
import logoNode2x from '../../../assets/images/Slider/node/node@2x.webp'
import logoNode3x from '../../../assets/images/Slider/node/node@3x.webp'
import logoSpringBoot from '../../../assets/images/Slider/spring-boot/spring-boot.webp'
import logoSpringBoot2x from '../../../assets/images/Slider/spring-boot/spring-boot@2x.webp'
import logoSpringBoot3x from '../../../assets/images/Slider/spring-boot/spring-boot@3x.webp'
import logoTerraform from '../../../assets/images/Slider/terraform/terraform.webp'
import logoTerraform2x from '../../../assets/images/Slider/terraform/terraform@2x.webp'
import logoTerraform3x from '../../../assets/images/Slider/terraform/terraform@3x.webp'
import s from './index.module.scss'
import './index.scss'

const Slider = () => {
  const responsive = {
    0: {
      items: 2
    },
    767: {
      items: 4
    },
    1439: {
      items: 6
    },
  }
  return (
    <>
      <div className={s['slider']}>
        <div className={s['slider__container']}>
          <p className={s['slider__title']}>Power to deploy the right tools</p>

          <AliceCarousel
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlayInterval={3000}
            autoPlayDirection="ltr"
            disableButtonsControls={true}
            mouseTracking={true}
            touchTracking={true}
            swipeDelta={true}
            swipeExtraPadding={true}
            keyboardNavigation={true}
          >
            <div className={s['slider__image']}>
              <img
                className={s['slider__logo']}
                src={logoDocker}
                alt='Docker'
                srcSet={`${logoDocker2x} 2x, ${logoDocker3x} 3x`} 
              />
            </div>
            <div className={s['slider__image']}>
              <img
                className={s['slider__logo']}
                src={logoKubernetes}
                alt='Kubernetes'
                srcSet={`${logoKubernetes2x} 2x, ${logoKubernetes3x} 3x`} 
              />
            </div>
            <div className={s['slider__image']}>
              <img
                className={s['slider__logo']}
                src={logoJenkins}
                alt='Jenkins'
                srcSet={`${logoJenkins2x} 2x, ${logoJenkins3x} 3x`} 
              />
            </div>
            <div className={s['slider__image']}>
              <img
                className={s['slider__logo']}
                src={logoSonaeqube}
                alt='Sonaeqube'
                srcSet={`${logoSonaeqube2x} 2x, ${logoSonaeqube3x} 3x`} 
              />
            </div>
            <div className={s['slider__image']}>
              <img
                className={s['slider__logo']}
                src={logoSwift}
                alt='Swift'
                srcSet={`${logoSwift2x} 2x, ${logoSwift3x} 3x`} 
              />
            </div>
            <div className={s['slider__image']}>
              <img
                className={s['slider__logo']}
                src={logoKotlin}
                alt='Kotlin'
                srcSet={`${logoKotlin2x} 2x, ${logoKotlin3x} 3x`} 
              />
            </div>
            <div className={s['slider__image']}>
              <img
                className={s['slider__logo']}
                src={logoJava}
                alt='java'
                srcSet={`${logoJava2x} 2x, ${logoJava3x} 3x`} 
              />
            </div>
            <div className={s['slider__image']}>
              <img
                className={s['slider__logo']}
                src={logoGrafana}
                alt='Grafana'
                srcSet={`${logoGrafana2x} 2x, ${logoGrafana3x} 3x`} 
              />
            </div>
            <div className={s['slider__image']}>
              <img
                className={s['slider__logo']}
                src={logoSql}
                alt='SQL'
                srcSet={`${logoSql2x} 2x, ${logoSql3x} 3x`} 
              />
            </div>
            <div className={s['slider__image']}>
              <img
                className={s['slider__logo']}
                src={logoC}
                alt='c#'
                srcSet={`${logoC2x} 2x, ${logoC3x} 3x`} 
              />
            </div>
            <div className={s['slider__image']}>
              <img
                className={s['slider__logo']}
                src={logoArgo}
                alt='Argo'
                srcSet={`${logoArgo2x} 2x, ${logoArgo3x} 3x`} 
              />
            </div>
            <div className={s['slider__image']}>
              <img
                className={s['slider__logo']}
                src={logoNode}
                alt='Node'
                srcSet={`${logoNode2x} 2x, ${logoNode3x} 3x`} 
              />
            </div>
            <div className={s['slider__image']}>
              <img
                className={s['slider__logo']}
                src={logoSpringBoot}
                alt='SpringBoot'
                srcSet={`${logoSpringBoot2x} 2x, ${logoSpringBoot3x} 3x`} 
              />
            </div>
            <div className={s['slider__image']}>
              <img
                className={s['slider__logo']}
                src={logoTerraform}
                alt='Terraform'
                srcSet={`${logoTerraform2x} 2x, ${logoTerraform3x} 3x`} 
              />
            </div>
          </AliceCarousel>
        </div>


      </div>
    </>
  )
}


export default Slider
