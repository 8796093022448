import s from './index.module.scss'

const Qualifications = ({ currentVacancy }) => {

  return (
    <>
      <div className={s['qualifications']}>
        <div className={s['qualifications__container']}>
          <h1 className={s['qualifications__title']}>{currentVacancy.qualifications.title}</h1>
          <div className={s['qualifications__list']}>
            {currentVacancy.qualifications.list.map((p, index) => (
              <div className={s['qualifications__list-item']} key={index}>
                <div className={s['qualifications__list-item__point']}></div>
                <p className={s['qualifications__list-item__text']}>{p}</p>
              </div>
            ))}
          </div>
          {currentVacancy.qualifications.subtitle &&
            <>
              <h2 className={s['qualifications__subtitle']}>{currentVacancy.qualifications.subtitle}</h2>
              <div className={s['qualifications__advantage-list']}>
                {currentVacancy.qualifications.advantageList.map((p, index) => (
                  <div className={s['qualifications__list-item']} key={index}>
                    <div className={s['qualifications__list-item__point']}></div>
                    <p className={s['qualifications__list-item__text']}>{p}</p>
                  </div>
                ))}
              </div>
            </>
          }
        </div>
      </div>
    </>
  )
}

export default Qualifications
