import { Link } from 'react-router-dom'
import en_Us from '../../../Language/text'
import s from './index.module.scss'

const VacanciesHome = () => {

  return (
    <>
      <div className={s['vacancies-home']} id="vacancies">
        <div className={s['vacancies-home__container']}>
          <h1 className={s['vacancies-home__title']}>{en_Us.home.vacancies.title}</h1>
          <div className={s['vacancies-home__content']} >
            <p className={s['vacancies-home__list-title']}>{en_Us.home.vacancies.engineering.title}</p>
            <div className={s['vacancies-home__list']}>
              <Link className={s['vacancies-home__button']} to="net-developer" >
                {en_Us.home.vacancies.engineering.list.netDeveloper}
              </Link>
              <Link className={s['vacancies-home__button']} to="java-developer" >
                {en_Us.home.vacancies.engineering.list.javaDeveloper}
              </Link>
              <Link className={s['vacancies-home__button']} to="creatio-low-coder" >
                {en_Us.home.vacancies.engineering.list.creatioLowCoder}
              </Link>
              <Link className={s['vacancies-home__button']} to="dev-ops-developer" >
                {en_Us.home.vacancies.engineering.list.devOps}
              </Link>
              <Link className={s['vacancies-home__button']} to="web-developer" >
                {en_Us.home.vacancies.engineering.list.webDeveloper}
              </Link>
              <Link className={s['vacancies-home__button']} to="ios-developer" >
                {en_Us.home.vacancies.engineering.list.iosDeveloper}
              </Link>
              <Link className={s['vacancies-home__button']} to="engineer-windows" >
                {en_Us.home.vacancies.engineering.list.engineerWindows}
              </Link>
              <Link className={s['vacancies-home__button']} to="engineer-linux" >
                {en_Us.home.vacancies.engineering.list.engineerLinux}
              </Link>
              <Link className={s['vacancies-home__button']} to="programator" >
                {en_Us.home.vacancies.engineering.list.programator}
              </Link>
            </div>
            <p className={s['vacancies-home__list-title']}>{en_Us.home.vacancies.qualityAssurance.title}</p>
            <div className={s['vacancies-home__list']}>
              <Link className={s['vacancies-home__button']} to="qa-automation-engineer" >
                {en_Us.home.vacancies.qualityAssurance.list.qaAutomation}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VacanciesHome
