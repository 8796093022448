import marker from '../../../assets/icons/markerWhite.svg'
import en_Us from '../../../Language/text'
import s from './index.module.scss'

const TheWayWeWork = () => {

  return (
    <>
      <div className={s['the-way-we-work']}>
        <div className={s['the-way-we-work__container']}>
          <p className={s['the-way-we-work__title']}>
            {en_Us.home.theWayWeWork.title}
          </p>
          <div className={s['the-way-we-work__list']}>
            {en_Us.home.theWayWeWork.list.map((p, index) => (
              <div className={s['the-way-we-work__list-item']} key={index}>
                <img
                  className={s['the-way-we-work__list-item__marker']}
                  src={marker}
                  alt={'✓'}
                />
                <p className={s['the-way-we-work__list-item__text']}>
                  {p}
                </p>
              </div>
            ))}
          </div>

        </div>
      </div>
    </>
  )
}

export default TheWayWeWork
