import iconUp from '../../assets/icons/icon-up.svg'

import s from './index.module.scss'

const Footer = () => {

  const scrollToTop = () =>
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  return (
    <>
      <footer className={s['footer']}>
        <div className={s['footer__copyright']}>
          © BC “MAIB” SA 1991—2022.
          Toate drepturile rezervate.
        </div>
        <img 
          className={s['footer_icon-up']}
          onClick={scrollToTop}
          src={iconUp}
          alt="up"
        />
      </footer>
    </>
  )
}

export default Footer
