import React from "react";
import { Route, Routes } from 'react-router-dom'
import Home from '../pages'
import NetDeveloper from "../pages/Vacancies/NetDeveloper";
import WebDeveloper from "../pages/Vacancies/WebDeveloper";
import IosDeveloper from "../pages/Vacancies/IosDeveloper";
import EngineerWindows from "../pages/Vacancies/EngineerWindows";
import EngineerLinux from "../pages/Vacancies/EngineerLinux";
import Programator from "../pages/Vacancies/Programator";
import DevOpsEngineer from "../pages/Vacancies/DevOpsEngineer";
import QAAutomationEngineer from "../pages/Vacancies/QAAutomationEngineer";
import JavaDeveloper from "../pages/Vacancies/JavaDeveloper";
import CreatioLowCoder from "../pages/Vacancies/CreatioLowCoder";
import ScrollToTop from "../components/ScrollToTop";

// import s from './index.module.scss'

const App = () => {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/net-developer" element={<NetDeveloper />} />
        <Route path="/web-developer" element={<WebDeveloper />} />
        <Route path="/ios-developer" element={<IosDeveloper />} />
        <Route path="/engineer-windows" element={<EngineerWindows />} />
        <Route path="/engineer-linux" element={<EngineerLinux />} />
        <Route path="/programator" element={<Programator />} />
        <Route path="/dev-ops-developer" element={<DevOpsEngineer />} />
        <Route path="/qa-automation-engineer" element={<QAAutomationEngineer />} />
        <Route path="/java-developer" element={<JavaDeveloper />} />
        <Route path="/creatio-low-coder" element={<CreatioLowCoder />} />
      </Routes>
    </>
  )
}

export default App
