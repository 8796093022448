import en_Us from '../../Language/text'
import s from './index.module.scss'

const ApplyButton = ({position}) => {
  return (
    <div
      className={'lightbox-220692752372357 ' + (position === 'top' ? s['apply-button__top'] : s['apply-button__bottom'])}
    >
      {en_Us.applyNow}
    </div>
  )
}

export default ApplyButton
