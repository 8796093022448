import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import en_Us from '../../../Language/text'
import ApplyButton from '../../ApplyButton'
import s from './index.module.scss'

const Description = ({ currentVacancy, imageName }) => {

  const isDesktopLayout = useMediaQuery({ query: '(min-width: 1440px)' })

  return (
    <>
      <div className={s['description'] + ' ' +s['description--' + imageName]}>
        <div className={s['description__container'] + ' ' +s['description__container--' + imageName]}>
          <Link
            className={s['description__button']}
            to="/"
          >
            <p className={s['description__button__arrow']}>❮ </p>
            <p>{en_Us.back}</p>
          </Link>
          <h1 className={s['description__title']}>{currentVacancy.description.title}</h1>
          <div className={s['text-wrapper']}>
            {currentVacancy.description.text.map((p, index) => (
              <p key={index} className={s['text']}>{p}</p>
            ))}
          </div>
          {isDesktopLayout && <ApplyButton position={'top'} />}
        </div>
      </div>
      {!isDesktopLayout && <ApplyButton position={'top'} />}
    </>
  )
}

export default Description
