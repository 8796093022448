import en_Us from '../../../Language/text'
import ScrollToVacancies from '../../ScrollToVacancies'
import s from './index.module.scss'

const DescriptionHome = () => { 
  return (
    <>
      <div className={s['description-home']}>
        <div className={s['description-home__container']}>
          
          <h1 className={s['description-home__title']}>{en_Us.home.description.title}</h1>
          <div className={s['description-home__text-wrapper']}>
              <p className={s['description-home__text']}>{en_Us.home.description.text}</p>
          </div>
          <div
            className={s['description-home__button']}
            onClick={ScrollToVacancies}
          >
            <p>{en_Us.home.description.button}</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default DescriptionHome
